import * as React from 'react'
import { useState } from 'react';
import { Link } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll';
import * as navHeaderStyle from '../styles/navHeader.module.scss'



const NavHeader = ({ children }) => {

  const [toggleNav, setToggleNav]=useState(false)

  const [open, setOpen] = useState(false);

  const mobileNavButton = open ? "toClose" : "toOpen";

  const showMenu=()=> setToggleNav(!toggleNav);


  

    return (


      <header id="top">
          <div className={navHeaderStyle.headerContainer}>
              <Link to="/">
              <div className={navHeaderStyle.logoElement}></div>
              </Link>
              <nav>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/spravy">Správy</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/eGovernment">eGovernment</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/projekty">Projekty</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/o-spolocnosti">O spoločnosti</Link>
                    <a onClick={() => scrollTo('#contact')}>Kontakt</a>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/en">EN</Link>
              </nav>
             <div id={navHeaderStyle.mobileNavButtonId} className={mobileNavButton} onClick={() => {setOpen(!open);showMenu()}}></div>
              
              
          </div>
          {toggleNav && <div className={navHeaderStyle.mobileNavContainer}>
                    <nav className={navHeaderStyle.mobileNav}>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/spravy">Správy</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/eGovernment">eGovernment</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/projekty">Projekty</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/o-spolocnosti">O spoločnosti</Link>
                    <a onClick={() => {scrollTo('#contact');setOpen(!open);showMenu()}}>Kontakt</a>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/en">EN</Link>
                    </nav>
            </div>}
      </header>
     
    )
  }


  export default NavHeader