import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/peterkrajcovic/Documents/Web/GatsbyProject/globaltel_V5_actual/src/components/newsLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>Firma GlobalTel získala zaradenie medzi VMWare partnerov v oblasti moderných aplikácií</h1>
    <p>
    Firma GlobalTel opäť potvrdila svoju technickú pripravenosť a vyspelosť. Od spoločnosti Dell získala zaradenie medzi VMWare partnerov v oblasti moderných aplikácií. <a href="../o-spolocnosti/#certificates" target="blank">globaltel.sk/o-spolocnosti/certifikaty</a>
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      